<template>
  <div class="sign-in" ref="forgotPassword">
    <q-card class="sign-in__card --emphasize">
      <q-card-section class="text-center">
        <div class="flex items-center justify-center">
          <img
            :src="$store.state.branding.logoDark"
            style="width: 185px"
            class="q-my-md"
          />
        </div>
      </q-card-section>

      <template v-if="!requestSent">
        <q-card-section class="q-py-none">
          <p class="q-pb-xs text-weight-medium">
            Forgot Your Password?
          </p>
          <p class="q-mb-none text-font-secondary">
            We'll send you a recovery link so you can reset your password.
            Please provide the email address associated with your account.
          </p>
        </q-card-section>

        <q-card-section>
          <q-form class="sign-in__form" @submit="submitRecoveryLinkRequest">
            <div class="row q-col-gutter-y-sm q-col-gutter-x-md">
              <div class="col-12">
                <q-input
                  filled
                  v-model="email"
                  label="Email Address"
                  lazy-rules
                  :rules="[
                    val =>
                      (val && val.length > 0) ||
                      'Please enter your email address.'
                  ]"
                  :disable="submittingRequest"
                  :error-message="invalidMessage"
                  :error="invalidMessage !== ''"
                  @focus="invalidMessage = ''"
                />
              </div>
            </div>

            <div class="sign-in__nav-container q-mt-sm">
              <router-link :to="{ name: 'SignIn' }" class="text-subtle">
                Return to Sign In
              </router-link>
              <q-btn
                label="Send Recovery Link"
                type="submit"
                color="primary"
                :disable="submittingRequest"
                :loading="submittingRequest"
              />
            </div>
          </q-form>
        </q-card-section>
      </template>
      <template v-else>
        <q-card-section class="column items-center justify-center q-pt-none">
          <q-icon
            color="positive"
            size="xl"
            name="sym_r_check_circle"
            class="q-mb-sm --icon-filled"
          />
          <p class="text-weight-medium">
            Recovery Link Sent
          </p>
          <small class="text-font-secondary">
            {{ email }}
          </small>
        </q-card-section>
        <q-card-section>
          <div class="sign-in__nav-container q-mt-sm">
            <router-link :to="{ name: 'SignIn' }" class="text-subtle">
              Return to Sign In
            </router-link>
          </div>
        </q-card-section>
      </template>
    </q-card>
  </div>
</template>

<script>
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      submittingRequest: false,
      invalidMessage: "",
      requestSent: false
    };
  },
  methods: {
    submitRecoveryLinkRequest() {
      this.submittingRequest = true;

      this.$store
        .dispatch("users/POST_REQUEST", {
          endpoint: "send-user-password-reset-email",
          params: {
            email: this.email
          }
        })
        .then(() => {
          this.requestSent = true;
        })
        .catch(error => {
          if (error.response.status === 400) {
            this.invalidMessage = "Invalid email address.";
          } else {
            this.invalidMessage = "An error occurred.";
          }
        })
        .finally(() => {
          this.submittingRequest = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.sign-in {
  @include display-flex(column, center, center);
  @include fullscreen-height-w-titlebar();

  .sign-in__card {
    width: 500px;
  }

  .sign-in__nav-container {
    @include display-flex(row, space-between, center);
  }
}
</style>
